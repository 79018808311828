export interface AuthorizationResult {
  tokenData: TokenData;
  isAdditionalAuthorizationRequired: boolean;
  additionalAuthorizationType?: AdditionalAuthorizationType;
  user?: {
    coverImageUrl?: string;
    avatarUrl?: string;
    departmentName?: string;
    displayName?: string;
    phoneNumber?: string;
    email?: string;
  };
}

interface TokenData {
  client_id?: string;
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

export enum AdditionalAuthorizationType {
  auth0 = "auth0",
  email = "email",
  sms = "sms"
}

export enum AuthFlow {
  passwordless = "passwordless",
  webFlow = "webFlow"
}
