import * as React from "react";
import { LocalStorageCache, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { Identity } from "../domain/user";
import { Backdrop, CircularProgress } from "@mui/material";
import { colors, theme } from "@talkouttech/portal-shared";
import { useTranslation } from "react-i18next";
import { client } from "../utils/api-client";
import { useToast } from "./toast-context";
import { getIdentyFromToken } from "../utils/user-utils";
import { parse } from "query-string";
import { useApiVersion } from "../application/features";

type TokenRefreshResonse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  id_token: string;
  token_type: string;
};

export type AuthContextType = {
  identity?: Identity | null;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  logout?: (location?: string) => void;
  loginWithAuth0Redirect?: (options?: RedirectLoginOptions) => void;
  i18n?: unknown;
  setImpersonationToken?: (token: string | null) => void;
  authCheckRequested?: (token?: string) => void;
  isImpersonating?: boolean;
};

const AuthContext = React.createContext<AuthContextType>({});
AuthContext.displayName = "AuthContext";

const AuthProvider: React.FC = props => {
  const [identity, setIdentity] = React.useState<Identity | null>(null);

  const { key } = parse(window.location.search);

  const [impersonationToken, setImpersonationTokenInternal] = React.useState<string | null>(null);
  const [isPasswordlessLoading, setIsPasswordlessLoading] = React.useState(true);

  const { isAuthenticated, getAccessTokenSilently, logout: auth0Logout, isLoading, loginWithRedirect } = useAuth0();
  const { i18n } = useTranslation();

  const logout = React.useCallback(
    (location?: string) => {
      localStorage.removeItem("portalClientId");
      localStorage.removeItem("portalRefreshToken");
      localStorage.removeItem("portalTempToken");
      localStorage.removeItem("portalActiveTenant");
      setIsPasswordlessLoading(false);
      auth0Logout({ returnTo: location || window.location.origin });
    },
    [auth0Logout]
  );

  const loginWithAuth0Redirect = React.useCallback(
    (options?: RedirectLoginOptions) => {
      loginWithRedirect(options);
    },
    [loginWithRedirect]
  );

  const handleSettingIdentity = React.useCallback((token: string) => {
    const identity = getIdentyFromToken(token);

    setIdentity(identity);
    setIsPasswordlessLoading(false);
  }, []);

  const getTokenFromRefreshToken = React.useCallback(
    async (refreshToken: string) => {
      if (key !== null && key !== undefined) {
        return;
      }
      const clientIdOverride = localStorage.getItem("portalClientId");
      const shouldUsePasswordlessRefresh = !!clientIdOverride && clientIdOverride !== window.APP_CONFIG.AUTH0_CLIENTID;
      try {
        const data = await client<TokenRefreshResonse>(
          shouldUsePasswordlessRefresh ? `auth/refresh` : "",
          {
            method: "POST",

            data: shouldUsePasswordlessRefresh
              ? {
                  clientId: clientIdOverride,
                  refreshToken
                }
              : {
                  grant_type: "refresh_token",
                  client_id: window.APP_CONFIG.AUTH0_CLIENTID,
                  refresh_token: refreshToken,
                  scope: `openid offline_access profile email appenv:${window.APP_CONFIG.ENVIRONMENT_NAME}`,
                  audience: window.APP_CONFIG.AUTH0_AUDIENCE,
                  redirectUri: `${window.location.origin}/home`
                }
          },
          shouldUsePasswordlessRefresh ? undefined : `https://${window.APP_CONFIG.AUTH0_DOMAIN}/oauth/token`
        );
        localStorage.setItem("portalRefreshToken", data.refresh_token);
        return data.access_token;
      } catch (error) {
        return undefined;
      }
    },
    [key]
  );

  const authCheckRequested = React.useCallback(
    async (token?: string) => {
      setIsPasswordlessLoading(true);
      const tempToken = localStorage.getItem("portalTempToken");
      if (tempToken && token) {
        client(`auth/link`, { token, method: "POST", data: { secondaryUserAccessToken: tempToken } }).then(() => {
          localStorage.removeItem("portalTempToken");
        });
      }
      if (impersonationToken) {
        handleSettingIdentity(impersonationToken);
        return;
      } else if (token) {
        handleSettingIdentity(token);
        return;
      } else if (isAuthenticated) {
        getAccessTokenSilently().then(token => {
          handleSettingIdentity(token);

          // Save Auth0 refresh token to enable easier use later
          const tokenCache = new LocalStorageCache();
          const key = tokenCache.allKeys().find(key => key.includes("auth0spa"));
          if (key) {
            const cachedValue = tokenCache.get(key) as any;
            const refreshToken = cachedValue?.body?.refresh_token;
            if (refreshToken) {
              localStorage.setItem("portalRefreshToken", refreshToken);
            }
          }
          return;
        });
      }

      const refreshToken = localStorage.getItem("portalRefreshToken");
      if (!refreshToken) {
        setIsPasswordlessLoading(false);
        return;
      }

      const tokenFromRefToken = await getTokenFromRefreshToken(refreshToken);
      if (!tokenFromRefToken) {
        setIsPasswordlessLoading(false);
        return;
      }

      handleSettingIdentity(tokenFromRefToken);
    },
    [getAccessTokenSilently, getTokenFromRefreshToken, handleSettingIdentity, impersonationToken, isAuthenticated]
  );

  React.useEffect(() => {
    if (!isAuthenticated) {
      authCheckRequested();
    } else {
      getAccessTokenSilently().then(token => {
        const tempToken = localStorage.getItem("portalTempToken");
        if (!tempToken) {
          authCheckRequested();
        } else {
          client(`auth/link`, { token, method: "POST", data: { secondaryUserAccessToken: tempToken } }).then(() => {
            localStorage.removeItem("portalTempToken");
            const identity = getIdentyFromToken(token);
            setIdentity(identity);
          });
        }
      });
    }
  }, [authCheckRequested, getAccessTokenSilently, isAuthenticated]);

  if (isLoading)
    return (
      <Backdrop style={{ zIndex: theme.zIndex.drawer + 1, color: colors.white }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  const value: AuthContextType = {
    isAuthenticated: identity !== null,
    logout,
    identity,
    isLoading: isPasswordlessLoading || isLoading,
    loginWithAuth0Redirect,
    i18n,
    authCheckRequested,
    setImpersonationToken: (token: string | null) => {
      setImpersonationTokenInternal(token);
    },
    isImpersonating: !!impersonationToken
  };
  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

function useClient<T>() {
  const { identity } = useAuth();
  const { showSnackBar } = useToast();
  const token = identity?.token;
  const { apiVersion } = useApiVersion();

  return React.useCallback(
    (endpoint: string, config?) => client<T>(endpoint, { ...config, token, showSnackBar, apiVersion }),
    [token, showSnackBar, apiVersion]
  );
}

export { AuthProvider, useAuth, useClient };
