import { GlobalStyles } from "@mui/material";
import { theme } from "@talkouttech/portal-shared";

export const globalStyles = (
  <GlobalStyles
    styles={{
      "*": {
        /* Works on Firefox */
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.colors.notStarted} ${theme.colors.border}`,
        /* Works on Chrome, Edge, and Safari */
        "&::-webkit-scrollbar": {
          width: "6px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: `${theme.colors.border}`
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: `${theme.colors.notStarted}`,
          border: `${theme.borders.standard}`,
          borderRadius: "2.5px",
          width: "4px"
        }
      },
      /* spacing in phone number dropdown between country name and dial code,
 I didn't find a solution to style it different way */
      ".country-name": {
        marginRight: theme.spacing(0.5)
      }
    }}
  />
);
