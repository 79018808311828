const apiURL = window.APP_CONFIG.API_URL;

async function client<T>(
  endpoint: string,
  {
    data,
    formData,
    token,
    headers: customHeaders,
    showSnackBar,
    informAboutNotFound = true,
    mapNotFoundToNull = false,
    apiVersion,
    stream = false,
    versionOverride,
    ...customConfig
  }: any = {},
  otherEndpoint?: string
): Promise<T> {
  const config = {
    method: data ? "POST" : "GET",
    // We want to know if there is formData in payload. If yes we don't want to stringify it and have information about using formData
    // Base on that we can remove it from headers later. Related to WebKit boundry here: https://stackoverflow.com/questions/3508338/what-is-the-boundary-in-multipart-form-data
    body: formData ? data : data ? JSON.stringify(data) : undefined,
    headers: {
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders
    },
    ...customConfig
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (formData) {
    delete config.headers["Content-Type"];
  }

  const base = config.apiBaseUrlOverride ?? apiURL;

  const url = otherEndpoint
    ? otherEndpoint
    : versionOverride
    ? `${base}${`/v${versionOverride}`}/${endpoint}`
    : `${base}${apiVersion !== undefined ? `/v${apiVersion}` : ""}/${endpoint}`;

  return fetch(url, config)
    .then(async response => {
      if (response.status === 401) {
        return Promise.reject({ message: "Please re-authenticate." });
      } else if (response.status === 204) {
        return null;
      }
      if (stream) {
        return response;
      } else {
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          const message = buildErrorMessage(response, data);
          const untranslatedText = undefined;
          if (response.status === 404 && !informAboutNotFound) {
            return Promise.reject(data);
          } else if (response.status === 404 && mapNotFoundToNull) {
            return null;
          } else {
            showSnackBar && showSnackBar(message, untranslatedText, "error");
            return Promise.reject(data);
          }
        }
      }
    })
    .catch(err => {
      if (informAboutNotFound) {
        showSnackBar && showSnackBar(err[0]?.message || "An error occurred. Please try again.", undefined, "error");
        return Promise.reject(err);
      }
    });
}

function buildErrorMessage(response: Response, data: any) {
  switch (response.status) {
    case 400:
      return data[0]?.message || "Error occured";
    case 404:
      return "Not Found";
    case 403:
    case 417:
      return data.message || "Not Allowed";
    default:
      return "An error occurred. Please try again.";
  }
}

export { client };
