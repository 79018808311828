import React, { FC, useCallback } from "react";
import { Alert, Snackbar } from "@mui/material";

import { Trans } from "react-i18next";

export type ToastContextType = {
  showSnackBar?: (
    text: string,
    untranslatable?: string,
    severity?: "error" | "success" | "info" | "warning" | undefined
  ) => void;
};

const ToastContext = React.createContext<ToastContextType>({});
// 👇 This is for loging readybility
ToastContext.displayName = "ToastContext";

interface Options {
  show: boolean;
  severity?: "error" | "success" | "info" | "warning" | undefined;
  text: string;
  untranslatable?: string;
}

const ToastProvider: FC = props => {
  const [options, setOptions] = React.useState<Options>({
    show: false,
    severity: undefined,
    text: "",
    untranslatable: ""
  });

  const value = {
    showSnackBar: (
      text: string,
      untranslatable?: string,
      severity?: "error" | "success" | "info" | "warning" | undefined
    ) => {
      setOptions({ show: true, severity, text, untranslatable });
    }
  };

  const onClose = useCallback(
    () => setOptions({ show: false, severity: undefined, text: "", untranslatable: undefined }),
    []
  );

  return (
    <>
      <ToastContext.Provider value={value} {...props} />
      {(options.text !== "" || options.untranslatable) && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={options.show}
          autoHideDuration={3000}
          onClose={onClose}
        >
          <Alert sx={{ width: "auto" }} data-testid="alert" severity={options.severity || "success"} onClose={onClose}>
            <Trans i18nKey={`alerts.${options.text}`}>{options.text}</Trans> {options.untranslatable}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

function useToast() {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
}

export { ToastProvider, useToast };
