import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useClient } from "../../context/auth-context";
import { AuthorizationResult } from "../../domain/auth";
import { client } from "../../utils/api-client";
import { useLocalStorage } from "../../utils/hooks";
import { isEmailValid } from "../../utils/validation";

const clientId = window.APP_CONFIG.AUTH0_CLIENTID;

export const useAuthMethod = (email: string | undefined) => {
  const [method, setMethod] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMethod = useCallback(async () => {
    if (!email) return;
    const isValid = isEmailValid(email);
    if (!isValid) return;
    setIsLoading(true);
    try {
      const method = await client<{ authFlow: string }>("auth/flow", {
        method: "POST",
        data: { email }
      });
      setMethod(method.authFlow);
      setIsLoading(false);
    } catch (err) {
      setMethod(undefined);
      setIsLoading(false);
      console.error(err);
    }
  }, [email]);

  useEffect(() => {
    getMethod();
  }, [getMethod]);

  return { method, isLoading };
};

export type CodePayload = EmailCodePayload | PhoneCodePayload;

type EmailCodePayload = {
  email: string;
};

type PhoneCodePayload = {
  phoneNumber: string;
};

export const useSendCode = (successCallback?: () => void, errorCallback?: () => void) => {
  const mutation = useMutation<any, Error, CodePayload>(
    payload => {
      return client(`auth/code?$checkExists=true`, {
        method: "POST",
        data: {
          ...payload,
          clientId,
          flowVersion: 2
        }
      });
    },
    {
      onSuccess: () => {
        successCallback && successCallback();
      },
      onError: () => {
        errorCallback && errorCallback();
      }
    }
  );

  return mutation;
};

type ValidateCodePayload = {
  email?: string;
  phoneNumber?: string;
  code: string;
};

export const useValidateCode = (
  callback: (result: AuthorizationResult) => void,
  errorCallback?: (message: string) => void
) => {
  const mutation = useMutation<any, Error, ValidateCodePayload>(
    payload => {
      return client(`auth/token`, {
        method: "POST",
        data: {
          ...payload,
          clientId,
          flowVersion: 2
        }
      });
    },
    {
      onSuccess: (result: AuthorizationResult, variables) => {
        if (result.isAdditionalAuthorizationRequired)
          localStorage.setItem("portalTempToken", result.tokenData.access_token);

        if (!result.isAdditionalAuthorizationRequired) {
          if (result.tokenData.client_id) {
            localStorage.setItem("portalClientId", result.tokenData.client_id);
          }
          localStorage.setItem("portalRefreshToken", result.tokenData.refresh_token);
        }

        callback(result);
      },
      onError: err => {
        if (errorCallback) errorCallback(err.message);
      }
    }
  );

  return mutation;
};

export interface Invitation {
  id: string;
  organisationUserId: string;
  organisationName: string;
  firstName: string;
  phoneNumber?: string;
  lastName: string;
  email: string;
  inviteUrl: string;
  tenantId: string;
}

export const useInvitation = (inviteKey: string) => {
  const client = useClient<Invitation>();
  const { data: invite, isLoading } = useQuery<Invitation, Error>({
    queryKey: [`invitation`, inviteKey],
    queryFn: async () => {
      return await client(`activeinvitations/${inviteKey}`);
    }
  });

  return { invite, isLoading };
};

export const useAcceptInvitation = (successCallBack: () => void) => {
  const client = useClient();
  const mutation = useMutation<unknown, Error, { inviteKey: string }>(
    updates =>
      client(`activeinvitations/${updates.inviteKey}/accept`, {
        method: "PUT"
      }),
    {
      onSuccess: () => {
        successCallBack && successCallBack();
      }
    }
  );

  return mutation;
};

export interface SignupData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const useSignup = (successCallBack: () => void) => {
  const client = useClient();
  const mutation = useMutation<unknown, Error, { inviteKey: string; details: SignupData }>(
    data =>
      client(`activeinvitations/${data.inviteKey}/signup`, {
        method: "POST",
        data: data.details
      }),
    {
      onSuccess: () => {
        successCallBack && successCallBack();
      }
    }
  );

  return mutation;
};

export const useRedirectAfterAuthUrl = () => {
  return useLocalStorage("portal-redirect-after-auth-url", "");
};
