import React, { useState } from "react";

export type PostFormFile = { id: number; data: File };

export interface PostFormData {
  textOfPost: string;

  photoFiles: PostFormFile[];
  videoFiles: PostFormFile[];
  attachmentFiles: PostFormFile[];
  isMandatory: boolean;
  isResource: boolean;
  disableInteractions: boolean;
  setTextOfPost: React.Dispatch<React.SetStateAction<string>>;
  setIsMandatory: React.Dispatch<React.SetStateAction<boolean>>;
  setIsResource: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableInteractions: React.Dispatch<React.SetStateAction<boolean>>;
  setPhotoFiles: React.Dispatch<React.SetStateAction<PostFormFile[]>>;
  setVideoFiles: React.Dispatch<React.SetStateAction<PostFormFile[]>>;
  setAttachmentFiles: React.Dispatch<React.SetStateAction<PostFormFile[]>>;
}

const PostContext = React.createContext<PostFormData | undefined>(undefined);

PostContext.displayName = "PostContext";

function PostProvider(props: any): JSX.Element {
  const [textOfPost, setTextOfPost] = useState("");
  const [isMandatory, setIsMandatory] = useState(false);
  const [isResource, setIsResource] = useState(false);
  const [disableInteractions, setDisableInteractions] = useState(false);
  const [photoFiles, setPhotoFiles] = useState<PostFormFile[]>([]);
  const [videoFiles, setVideoFiles] = useState<PostFormFile[]>([]);
  const [attachmentFiles, setAttachmentFiles] = useState<PostFormFile[]>([]);

  const value = {
    textOfPost,
    isMandatory,
    isResource,
    disableInteractions,
    photoFiles,
    videoFiles,
    attachmentFiles,
    setTextOfPost,
    setIsMandatory,
    setIsResource,
    setDisableInteractions,
    setPhotoFiles,
    setVideoFiles,
    setAttachmentFiles
  };

  return <PostContext.Provider value={value} {...props} />;
}

function useEditPost() {
  const context = React.useContext(PostContext);

  if (context === undefined) {
    throw new Error(`useEditPost must be used within a PostProvider`);
  }
  return context;
}

export { PostProvider, useEditPost };
