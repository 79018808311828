export const tagManagerArgs = {
  gtmId: window.APP_CONFIG.GTM_ID,
  auth: window.APP_CONFIG.GTM_AUTH,
  preview: window.APP_CONFIG.GTM_PREVIEW
};

export const managerGTMArgs = (pagePath: string, pageTitle: string) => {
  return {
    dataLayer: {
      pagePath,
      pageTitle
    }
  };
};
