import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth-context";
import { ToastProvider } from "./toast-context";
import { ThemeProvider } from "@mui/material/styles";
import { ModalProvider } from "./modal-context";
import { TenantProvider } from "./tenant-context";
import { Loader, theme } from "@talkouttech/portal-shared";
import { PostProvider } from "./post-context";
import { ImpersonationContextProvider } from "./impersonation-context";

const MILLISECONDS_IN_HOUR = 1000 * 60 * 60;

// Added this to prevent the test log spam with ReactQueryDevTools!
const isRunningInsideTest = !!process.env.JEST_WORKER_ID;

const queryConfig = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: MILLISECONDS_IN_HOUR,
      cacheTime: MILLISECONDS_IN_HOUR
    }
  }
});

export const BasicProviders: FC = ({ children }) => (
  <React.Suspense fallback={<Loader />}>
    <QueryClientProvider client={queryConfig}>
      <Router>
        <ThemeProvider theme={theme}>
          <PostProvider>
            <ToastProvider>
              <ImpersonationContextProvider>
                <ModalProvider>{children}</ModalProvider>
              </ImpersonationContextProvider>
            </ToastProvider>
          </PostProvider>
        </ThemeProvider>
      </Router>
      {!isRunningInsideTest && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </React.Suspense>
);

const AppProviders: FC = ({ children }) => (
  <Auth0Provider
    domain={window.APP_CONFIG.AUTH0_DOMAIN}
    clientId={window.APP_CONFIG.AUTH0_CLIENTID}
    redirectUri={`${window.location.origin}/home`}
    audience={window.APP_CONFIG.AUTH0_AUDIENCE}
    useRefreshTokens
    cacheLocation="localstorage"
    onRedirectCallback={appState => {
      if (appState?.returnTo) {
        window.location.href = appState!.returnTo;
      }
    }}
    scope={`openid offline_access profile email appenv:${window.APP_CONFIG.ENVIRONMENT_NAME}`}
  >
    <AuthProvider>
      <TenantProvider>
        <BasicProviders>{children}</BasicProviders>
      </TenantProvider>
    </AuthProvider>
  </Auth0Provider>
);

export { AppProviders };
