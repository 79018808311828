export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const URL_REGEX = /https?:\/\/[\w./\-=!?&<>[\]]+/gi;

export const HASHTAG_REGEX_SAFARI = `(?:(^|\\s))#[^\\s#]+(?=($|\\s))`;

export const UI = {
  pageMaxWidth: 1440,
  mainWrapperMaxWidth: 1920
};

export const APP_STORE_LINK = "https://apps.apple.com/gb/app/talkout-paths/id1573596224";
export const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=com.talkout.talkoutapp";
