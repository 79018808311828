import * as React from "react";
import { Tenant } from "../domain/company";
import { client } from "../utils/api-client";
import { useLocalStorage } from "../utils/hooks";
import { useAuth } from "./auth-context";
import { Loader } from "@talkouttech/portal-shared";
export type TenantContextType = {
  setActiveTenant: (tenantId: string) => void;
  tenants: Tenant[];
  activeTenant: string;
};

const TenantContext: React.Context<TenantContextType> = React.createContext<TenantContextType>({
  setActiveTenant: () => {},
  tenants: [],
  activeTenant: ""
});

TenantContext.displayName = "TenantContext";

function TenantProvider(props: any): JSX.Element {
  const { identity, isAuthenticated } = useAuth();

  const [tenants, setTenants] = React.useState<Tenant[] | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [activeTenant, setTenant] = useLocalStorage<string | null>("portalActiveTenant", null);

  const setActiveTenant = (tenantId: string) => setTenant(tenantId);

  React.useEffect(() => {
    setIsLoading(true);

    if (!isAuthenticated) {
      setTenant(null);
    }

    if (identity && !activeTenant && tenants === null) {
      client<Tenant[]>("tenants", { token: identity.token }).then(tenants => {
        if (!identity) return;

        setTenant(tenants[0]?.id);
        setTenants(tenants);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [activeTenant, setTenant, identity, isAuthenticated, tenants]);

  if (isLoading) return <Loader />;

  const value = { tenants, activeTenant, setActiveTenant };
  return <TenantContext.Provider value={value} {...props} />;
}

function useTenantContext() {
  const context = React.useContext(TenantContext);

  if (context === undefined) {
    throw new Error(`useTenantContext must be used within a TenantProvider`);
  }
  return context;
}

export { TenantProvider, useTenantContext };
