import React, { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProviders } from "./context/AppProviders";
import { Loader } from "@talkouttech/portal-shared";
import "./language/i18n";
import TagManager from "react-gtm-module";
import { tagManagerArgs } from "./utils/gtmDataLayers";
import { globalStyles } from "./styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
interface AppConfig {
  AUTH0_DOMAIN: string;
  AUTH0_AUDIENCE: string;
  AUTH0_CLIENTID: string;
  API_URL: string;
  APP_URL: string;
  ENVIRONMENT_NAME: string;
  FUSIONCHARTS_LICENSE_KEY: string;
  GTM_ID: string;
  GTM_AUTH: string;
  GTM_PREVIEW: string;
  GIPHY_API_KEY: string;
}

declare global {
  interface Window {
    APP_CONFIG: AppConfig;
  }
}

TagManager.initialize(tagManagerArgs);
// MUI-X Pro License.
// Documentation states this is designed to be publicly visible and should be embedded in source, env var mapping is not required
LicenseInfo.setLicenseKey(
  "65de47c4b30ccd21abac813118cdf768Tz05NDI5NixFPTE3NTI2NjE3MjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <AppProviders>
      <StrictMode>
        <CssBaseline />
        {globalStyles}
        <App />
      </StrictMode>
    </AppProviders>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
