import { createContext, useCallback, useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { useAuth, useClient } from "./auth-context";
import { useToast } from "./toast-context";
export interface PlatformUser {
  id: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  email?: string;
}

export interface ImpersonationDetails {
  impersonatedUser?: PlatformUser;
  impersonatedByUser?: PlatformUser;
  token: string;
}

interface ImpersonationState {
  isImpersonating: boolean;
  impersonatedUser?: PlatformUser;
  impersonatedByUser?: PlatformUser;
  startImpersonationSession: (id: string) => void;
  endImpersonationSession: () => void;
}

const defaultState: ImpersonationState = {
  isImpersonating: false,
  startImpersonationSession: () => undefined,
  endImpersonationSession: () => undefined
};

const ImpersonationContext = createContext<ImpersonationState>(defaultState);
ImpersonationContext.displayName = "ImpersonationContext";

const ImpersonationContextProvider = (props: any) => {
  const { isImpersonating, setImpersonationToken } = useAuth();
  const [impersonatedUser, setImpersonatedUser] = useState<PlatformUser | undefined>(undefined);
  const [impersonatedByUser, setImpersonatedByUser] = useState<PlatformUser | undefined>(undefined);
  const client = useClient<ImpersonationDetails>();
  const queryClient = useQueryClient();
  const { showSnackBar } = useToast();
  const [key, setKey] = useState<string>(Math.random().toString());

  const startImpersonationSession = useCallback(
    (id: string): void => {
      client(`platformusers/${id}/impersonationtoken`)
        .then(data => {
          if (!setImpersonationToken) {
            return;
          }
          setImpersonatedByUser(data.impersonatedByUser ? { ...data.impersonatedByUser } : undefined);
          setImpersonatedUser(data.impersonatedUser ? { ...data.impersonatedUser } : undefined);
          setImpersonationToken(data.token);

          queryClient.clear();
          setKey(Math.random().toString());
        })
        .catch(err => {
          let message: string;
          if (err && err[0] && err[0].property === "id") {
            message = err[0].message;
          } else {
            message = "Failed to start impersonation";
          }
          showSnackBar && showSnackBar(message, undefined, "error");
        });
    },
    [client, setImpersonationToken, queryClient, showSnackBar]
  );

  const endImpersonationSession = useCallback((): void => {
    setImpersonatedUser(undefined);
    setImpersonatedByUser(undefined);
    setImpersonationToken && setImpersonationToken(null);
    queryClient.clear();
    setKey(Math.random().toString());
  }, [setImpersonationToken, queryClient]);

  const value: ImpersonationState = {
    impersonatedUser,
    impersonatedByUser,
    isImpersonating: !!isImpersonating,
    startImpersonationSession,
    endImpersonationSession
  };

  return <ImpersonationContext.Provider key={key} value={value} {...props} />;
};

function useImpersonation() {
  const context = useContext(ImpersonationContext);

  if (context === undefined) {
    throw new Error(`useImpersonation must be used within a ImpersonationContextProvider`);
  }
  return context;
}

export { useImpersonation, ImpersonationContextProvider };
