import jwtDecode from "jwt-decode";
import { DecodedToken, Identity, TenantInfo } from "../domain/user";

export const TENANT_INFO_URL = "https://talkout.com/tenantInfo";

export const getIdentyFromToken = (token: string): Identity => {
  const decodedToken = jwtDecode<DecodedToken>(token);

  const permissions = decodedToken["permissions"] || [];
  const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_URL] || {};

  return {
    platformUserId: decodedToken.sub,
    token,
    tenantInfo,
    permissions
  };
};
